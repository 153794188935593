<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Check-in:</v-toolbar-title>
        <span style="margin:0 1em;transform:translateY(3px)">{{ title }}</span>
        <span style="transform:translateY(3px)">on</span>
        <span style="margin:0 1em;transform:translateY(3px)">{{ date }}</span>
        <v-spacer></v-spacer>
        <v-btn to="/checkin/event" exact>Back to List</v-btn>
      </v-toolbar>
      <v-toolbar>
        <v-text-field v-if="role !== 'Scanner'" v-model="attendeeFilter" label="Attendee List Filter" outlined dense hide-details>
          <template v-slot:prepend-inner>
            <v-icon small class="mt-1">fal fa-search</v-icon>
          </template>
        </v-text-field>
        <v-spacer></v-spacer>
        <share v-if="role === 'Admin'" :id="id"></share>
        <v-dialog v-model="scanDialog" width="500" persistent>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="success">Enable Scanning</v-btn>
          </template>
          <v-card>
            <v-card-title>Scanning Active</v-card-title>
            <v-card-text>Scan cards to add to the system. The name will appear below when the scan is completed.</v-card-text>
            <v-card-text>
              <v-text-field v-model="prox" ref="scanField" style="position:absolute;top:0;opacity:0" @keydown.enter="addScan" @blur="returnFocus"></v-text-field>
              <v-alert v-if="alertActive" :type="alertType">{{ alertText }}</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="scanDialog = false">Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table :items="attendees" :headers="headers" :search="attendeeFilter" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }">
        <template v-slot:no-data>
          <v-alert v-if="role === 'Scanner'" type="info" outlined>You do not have permission to view the saved scans but only the ones that you have recently scanned. Entries should show up here as they are scanned.</v-alert>
          <v-alert v-else type="info" outlined>There are currently no scanned-in people for this event.</v-alert>
        </template>
        <template v-slot:item.in="{ item }">{{ stringFormatDate(item.in) }}</template>
        <template v-slot:item.out="{ item }">{{ 'out' in item ? stringFormatDate(item.out) : '' }}</template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { ref, onMounted, computed, watch, onBeforeUnmount } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'
export default {
  components: {
    Share: () => import('@/components/checkin/Share')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const id = computed(() => root.$route.params.id)
    const role = ref('')
    const title = ref('')
    const date = ref('')
    const scanOut = ref(false)
    const attendees = ref([])
    const headers = computed(() => {
      if (scanOut.value === true) {
        return [
          { text: 'Scan In Date', value: 'in', filterable: false },
          { text: 'Scan Out Date', value: 'out', filterable: false },
          { text: 'Name', value: 'name' }
        ]
      } else {
        return [
          { text: 'Scan Date', value: 'in', filterable: false },
          { text: 'Name', value: 'name' }
        ]
      }
    })
    const searchActive = ref(false)
    const attendeeFilter = ref('')

    const eventServ = root.$feathers.service('checkin/event')

    const onPatchedListener = (data) => { if (data._id === id.value) load(data) }
    onMounted(async () => {
      if (id.value != null && id.value !== '') {
        await eventServ.get(id.value).then(load)
        if (role.value !== 'Scanner') eventServ.on('patched', onPatchedListener)
      }
    })

    onBeforeUnmount(() => { eventServ.removeListener('patched', onPatchedListener) })

    async function load (data) {
      // Make sure this user is in the share list and see what their role is
      const rec = data.share.filter(({ user: shareUser }) => shareUser === user.value.directoryId)
      if (rec.length === 0) {
        // This user does not have permission to access this event
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'You do not have access to the requested event' })
        root.$router.push('/checkin/event')
        return
      }
      role.value = rec[0].role
      title.value = data.title
      scanOut.value = data.scanOut || false
      let dt = new Date(data.date)
      let str = dt.toDateString()
      date.value = str.replace(' ', ', ').substring(0, str.length - 4) + ', ' + str.substring(str.length - 4)

      // New scans are just added at the end, so we just need to look at the ones that are beyond the length of the currently loaded attendees
      if (role.value === 'Admin' || role.value === 'Viewer') attendees.value = data.attendees.reverse()
    }

    const scanDialog = ref(false)
    watch(scanDialog, (val) => {
      if (val) {
        setTimeout(() => { scanField.value.$el.children[0].children[0].children[0].children[0].focus() }, 200)
      }
    })
    const scanField = ref(null)
    const prox = ref('')
    const scanName = ref('')

    async function addScan () {
      const rec = attendees.value.filter(({ prox: scanProx }) => scanProx === prox.value)
      if (rec.length > 0) {
        if (scanOut.value) {
          if ('out' in rec) {
            showAlert('error', 'User has already scanned out for this event')
          } else {
            eventServ.patch(null, { 'attendees.$.out': new Date() }, { _id: id.value, 'attendees.prox': prox.value })
          }
        } else {
          showAlert('error', 'User has already scanned in for this event')
        }
      } else {
        const { data } = await root.$feathers.service('directory/people').find({ query: { prox: prox.value } })
        if (data.length === 0) {
          showAlert('error', 'User\'s card was not found in the directory')
        } else if (data.length === 1) {
          const name = data[0].name.first + ' ' + data[0].name.last
          await eventServ.patch(id.value, { $push: { attendees: { user: data[0]._id, name, prox: prox.value, in: new Date() } } })
          if (role.value === 'Scanner') attendees.value.splice(0, 0, { user: data[0]._id, name, prox: prox.value, in: new Date() })
          showAlert('success', name + ' scanned successfully')
        } else {
          showAlert('error', 'Multiple users found with matching card')
        }
      }
      prox.value = ''
    }

    function returnFocus () {
      if (scanDialog.value === true) {
        setTimeout(() => {
          scanField.value.$el.children[0].children[0].children[0].children[0].focus()
        }, 100)
      }
    }

    const alertActive = ref(false)
    const alertType = ref('')
    const alertText = ref('')
    const alertTimeout = ref(null)
    function showAlert (type, text) {
      alertType.value = type
      alertText.value = text
      alertActive.value = true
      if (alertTimeout.value != null) clearTimeout(alertTimeout.value)
      alertTimeout.value = setTimeout(() => {
        alertActive.value = false
        alertType.value = ''
        alertText.value = ''
        alertTimeout.value = null
      }, 5000)
    }

    return {
      user,
      id,
      role,
      title,
      date,
      attendees,
      headers,
      searchActive,
      attendeeFilter,
      scanDialog,
      scanField,
      prox,
      scanName,
      addScan,
      returnFocus,
      alertActive,
      alertType,
      alertText,
      showAlert,
      stringFormatDate
    }
  }
}
</script>
